<template>
  <LandingLayout>
    <template #landing>
      <form @submit.prevent="submitForm" autocomplete="off">
        <div class="login-form">
          <div class="banner">
            <div class="title">
              Create an account
            </div>
            <div class="desc">
              Get access to endless opportunities as a affiliate
            </div>
            <img
                class="img"
                src="../../../assets/img/png/loginImg2x.png"
                srcset="../../../assets/img/png/loginImg1x.png 1x,
                  ../../../assets/img/png/loginImg2x.png 2x,
                  ../../../assets/img/png/loginImg4x.png 4x"
            />
          </div>
          <div class="login-form_block">
            <div class="switcher">
              <div class="switcher--item switcher--item_active">
                Sign up

                <div class="switcher--item_active--underline"></div>
              </div>
              <router-link :to="{name: 'Login'}" class="switcher--item"
              >
                Sign in

              </router-link>
            </div>
            <div class="inputs">
              <div
                  class="input-state input-state-icon"
                  :class="{'input-state-error': $v.form.login.$error}"
              >
                <input
                    v-model="form.login"
                    type="text"
                    v-model.trim="$v.form.login.$model"
                    required
                />
                <IconInputs
                    :icon="inputsSignUp.login.img"
                />
                <label>{{ inputsSignUp.login.input }}</label>
              </div>
              <div
                  class="input-state input-state-icon"
                  :class="{'input-state-error': $v.form.email.$error}"
              >
                <input
                    v-model="form.email"
                    type="text"
                    v-model.trim="$v.form.email.$model"
                    required
                />
                <IconInputs
                    :icon="inputsSignUp.email.img"
                />
                <label>{{ inputsSignUp.email.input }}</label>
              </div>
              <div
                  class="input-state input-state-icon"
                  :class="{'input-state-error': $v.form.password.$error}"
              >
                <input
                    v-model="form.password"
                    id="password"
                    v-model.trim="$v.form.password.$model"
                    :type="isShowPassword"
                    required
                    autocomplete="false"
                />
                <IconInputs
                    :icon="inputsSignUp.password.img"
                />
                <div @click="switchVisibility" class="showPassword">
                  <eye-password v-if="isShowPassword === 'password'"/>
                  <open-eye-password v-else/>
                </div>
                <label>{{ inputsSignUp.password.input }}</label>
              </div>
              <div
                  class="input-state input-state-icon"
                  :class="{'input-state-error': $v.form.password_confirm.$error}"
              >
                <input
                    v-model="form.password_confirm"
                    v-model.trim="$v.form.password_confirm.$model"
                    id="password"
                    :type="isShowPasswordConfirm"
                    required
                />
                <IconInputs
                    :icon="inputsSignUp.password.img"
                />
                <div @click="switchVisibilityConfirm" class="showPassword">
                  <eye-password v-if="isShowPasswordConfirm === 'password'"/>
                  <open-eye-password v-else/>
                </div>
                <label>{{ inputsSignUp.passwordConfirm.input }}</label>
              </div>
              <label class="error-text" v-if="error">Login or password invalid</label>
              <div class="error-message" v-if="errorRes">{{ errorRes }}</div>
              <div class="check-box" :class="{'check-box_error': errorChecked}">
                <div class="check-box_marker" @click="onChange">
                  <un-market-checkbox v-if="!isChecked" class="check-box_marker"/>
                  <marked-checkbox-icon v-if="isChecked" />
                </div>
                <label class="check-box_label">I’ve read and agree to <a href="https://swapuz.com/terms-of-use/">Swapuz Terms</a></label>
              </div>

              <button class="btn" type="submit" :disabled="!isChecked || !form.login || !form.email || !form.password || !form.password_confirm || $v.form.login.$error || $v.form.email.$error || $v.form.password.$error || $v.form.password_confirm.$error">
                Sign up
              </button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </LandingLayout>
</template>

<script>
import LandingLayout from "@/components/LandingLayout.vue";
import IconInputs from "@/components/IconInputs.vue";
import EyePassword from "@/assets/img/svg/eyePassword.vue";
import OpenEyePassword from "@/assets/img/svg/openEyePassword.vue";
import {email, minLength, required, sameAs} from "vuelidate/lib/validators";
import UnMarketCheckbox from "@/assets/img/svg/UnMarketCheckbox.vue";
import MarkedCheckboxIcon from "@/assets/img/svg/MarkedCheckboxIcon.vue";
import UserService from "@/services/user.service";

export default {
  name: "SignUp",
  components: {MarkedCheckboxIcon, UnMarketCheckbox, OpenEyePassword, EyePassword, IconInputs, LandingLayout},
  data() {
    return {
      tabSelect: 'SignUp',
      inputsSignUp: {
        login: {
          input: "Login",
          value: '',
          img: 'Login'
        },
        email: {
          input: "Your e-mail",
          value: '',
          img: 'Email'
        },
        password: {
          input: "Password",
          value: '',
          img: 'Password'
        },
        passwordConfirm: {
          input: "Password confirm",
          value: '',
          img: 'Password'
        },
      },
      form: {
        login: '',
        email: '',
        password: '',
        password_confirm: '',
        acceptTerms: false,
      },
      submitStatus: null,
      error: '',
      errorRes: '',
      isChecked: false,
      errorChecked: false,
      isShowPassword: 'password',
      isShowPasswordConfirm: 'password',
    }
  },
  validations: {
    form: {
      login: {
        required,
        minLength: minLength(4),
        doesNotContainCyrillic(value) {
          const cyrillicRegex = /[а-яА-Я]/;
          return !cyrillicRegex.test(value);
        },
        containsValidCharacters(value) {
          const validCharactersRegex = /^[a-zA-Z0-9_]+$/;
          return validCharactersRegex.test(value);
        }
      },
      email: {
        email,
        required
      },
      password: {
        required,
        minLength: minLength(6)
      },
      password_confirm: {
        required,
        sameAsPassword: sameAs('password')
      },
      acceptTerms: {
        required
      }
    }
  },
  mounted() {
    this.$emit('checkbox-change', (value) => {
      this.form.acceptTerms = value

      console.log(this.form.acceptTerms);
    })
  },
  methods: {
    onChange() {
      this.errorChecked = false;
      this.isChecked = !this.isChecked
      this.form.acceptTerms = this.isChecked;
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.form.acceptTerms) {
        const credential = {
          login: this.form.login,
          email: this.form.email,
          password: this.form.password
        };

        UserService.register(credential).then((result) => {

          if (result.status === 200) {
            const token = result.data.result.token;
            localStorage.token = token;
            this.$store.commit('setter', { token: token });
            this.errorRes = '';
            this.$router.push({name: 'Dashboard'})
          }
        }).catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.result) {
              this.errorRes = error.response.data.result.charAt(0).toUpperCase() + error.response.data.result.slice(1);
            } else {
              this.errorRes = 'Unknown error occurred';
            }
          } else {
            this.errorRes = error.message || 'Unexpected error occurred';
          }
        });
      } else {
        this.errorChecked = true
      }
    },
    switchVisibility() {
      if (this.isShowPassword === 'password') {
        this.isShowPassword = 'text'
      } else {
        this.isShowPassword = 'password'
      }
    },
    switchVisibilityConfirm() {
      if (this.isShowPasswordConfirm === 'password') {
        this.isShowPasswordConfirm = 'text'
      } else {
        this.isShowPasswordConfirm = 'password'
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/styles/components/login-form";
@import "../../../assets/styles/components/input-state";
</style>
